@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'AvertaStd-Regular';
    src: url('../public/fonts/AvertaStd-Regular.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-RegularItalic';
    src: url('../public/fonts/AvertaStd-RegularItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-Bold';
    src: url('../public/fonts/AvertaStd-Bold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-BoldItalic';
    src: url('../public/fonts/AvertaStd-BoldItalic.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-Semibold';
    src: url('../public/fonts/AvertaStd-Semibold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'AvertaStd-Semibolditalic';
    src: url('../public/fonts/AvertaStd-SemiboldItalic.woff') format('woff');
    font-display: swap;
  }

  html {
    @apply font-sans text-base leading-5.5 tracking-tight text-gray-900;
  }

  h1 {
    @apply font-sans-bold text-[32px] leading-9.5 tracking-tighter text-gray-900 md:text-[40px] md:leading-[48px] xl:text-[50px] xl:leading-[60px];
  }
  h2 {
    @apply font-sans-bold text-[28px] leading-8 tracking-tighter text-gray-900 md:text-[30px] md:leading-9 xl:text-[40px] xl:leading-[48px];
  }
  h3 {
    @apply font-sans-bold text-2xl leading-7 tracking-tighter text-gray-900 md:tracking-tight xl:text-[32px] xl:leading-[38px];
  }
  h4 {
    @apply font-sans-bold text-xl leading-6 tracking-tight text-gray-900 xl:text-[28px] xl:leading-[34px];
  }
  h5 {
    @apply font-sans-semibold text-lg leading-5 tracking-tight text-gray-900 xl:text-2xl xl:leading-7;
  }
  h6,
  .h6 {
    @apply font-sans-semibold text-base leading-5 tracking-tight text-gray-900 xl:text-lg xl:leading-5;
  }
  .h7 {
    @apply font-sans-semibold text-sm leading-4 text-gray-900 xl:text-base xl:leading-5;
  }
  .h8 {
    @apply font-sans-semibold text-[13px] leading-[15px] text-gray-900 md:tracking-tight xl:text-sm xl:leading-4;
  }
  .h9 {
    @apply font-sans-semibold text-xs leading-3.5 tracking-tight text-gray-900;
  }

  .p1 {
    @apply font-sans text-xl leading-7 tracking-tight text-gray-900 md:text-[22px] md:leading-[30px] xl:text-2xl xl:leading-[34px];
  }
  .p2 {
    @apply font-sans text-lg leading-6 tracking-tight text-gray-900 md:text-[20px] md:leading-7 xl:text-[22px] xl:leading-[30px];
  }
  .p3 {
    @apply font-sans text-base leading-5.5 tracking-tight text-gray-900 md:text-[18px] md:leading-6 xl:text-[20px] xl:leading-[28px];
  }
  .p4 {
    @apply font-sans text-[15px] leading-5 text-gray-900 md:text-[16px] md:leading-5.5;
  }
  .p5 {
    @apply font-sans text-sm leading-5 text-gray-900 xl:text-[15px] xl:leading-[21px];
  }
  .p6 {
    @apply font-sans text-[13px] leading-4.5 text-gray-900 xl:text-sm xl:leading-5;
  }
  .p7 {
    @apply font-sans text-xs leading-4 text-gray-900 xl:text-[13px] xl:leading-4.5;
  }
}
